import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { t, t2 } from '@/@core/libs/i18n/utils'
import { $themeConfig } from '@themeConfig'

export default function LineLiffTable() {
  // Use toast
  const toast = useToast()

  const refTable = ref(null)
  const lineOa = computed(() => store.state.lineapi.lineOa)

  // Table Handlers
  const tableColumns = [
    {
      key: 'id',
      sortable: true,
      label: `${t('ID')}`,
    },
    {
      key: 'login',
      sortable: true,
      label: `${t('Login Channel')}`,
    },
    {
      key: 'liff',
      sortable: true,
      label: `${t('Liff')}`,
    },
    {
      key: 'status',
      sortable: false,
      label: `${t('Status')}`,
    },
    {
      key: 'actions',
      label: t('Actions'),
    },
  ]

  const filterList = JSON.parse(localStorage.getItem(`${$themeConfig.app.session}-filter-line-liff`))
  const perPage = ref((filterList != null && filterList.perPage != null) ? filterList.perPage : 10)
  const totals = ref(0)
  // const currentPage = ref((filterList != null && filterList.page != null) ? filterList.page : 1)
  const currentPage = ref(1)
  const perPageOptions = [2, 5, 10, 25, 50, 100]
  const searchQuery = ref((filterList != null && filterList.q != null) ? filterList.q : '')
  const sortBy = ref((filterList != null && filterList.sortBy != null) ? filterList.sortBy : 'id')
  const isSortDirDesc = ref((filterList != null && filterList.sortDesc != null) ? filterList.sortDesc : true)
  const locale = ref('th')

  const dataMeta = computed(() => {
    const localItemsCount = refTable.value ? refTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totals.value,
    }
  })

  const refetchData = () => {
    if (refTable.value !== null) {
      refTable.value.refresh()
    }
  }

  watch([currentPage, perPage, searchQuery, locale], () => {
    refetchData()
  })

  const getList = (ctx, callback) => {
    const { id } = JSON.parse(lineOa.value)
    const params = {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      language: locale.value,
      lineOaId: id,
    }
    store
      .dispatch('line-liff-store/getListLineLiff', params)
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totals.value = total
        localStorage.setItem(`${$themeConfig.app.session}-filter-line-liff`, JSON.stringify(params))
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: t2('Error fetching {module} list', { module: t('Line Liff') }),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  const textFirstUpper = text => text.slice(0, 1).toUpperCase() + text.slice(1, text.length)

  return {
    getList,
    tableColumns,
    perPage,
    currentPage,
    totals,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    locale,
    refTable,
    filterList,

    refetchData,
    resolveUserStatusVariant,
    textFirstUpper,
  }
}
